import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { NavController } from "@ionic/angular";
import { environment } from "../../environments/environment";
import { AuthConfig, OAuthService } from "angular-oauth2-oidc";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private _clientId = new BehaviorSubject<string>(null);
  loginSub = new BehaviorSubject<boolean>(null);
  private authConfig: AuthConfig;
  public username: string;
  public useremail: string;
  public userFirstname: string;
  public userLastname: string;
  public userId: string;
  public authenticationHeaders = {
    Authorization: `Bearer ${this.oauthService.getAccessToken()}`,
    "accept-version": "1.0",
  };

  constructor(private navCrtl: NavController, private oauthService: OAuthService, private router: Router) {
    if (environment.production) {
      this.authConfig = {
        issuer: environment.groupIdUrl,
        strictDiscoveryDocumentValidation: false,
        redirectUri: "https://yardcam.doka.com/login",
        clientId: "fe1b7b55-6176-422f-985b-c5f2454962c1",
        scope:
          "openid profile roles yardcam.doka.com/api api.doka.com/branches.read yard-api.doka.com/branches.read yard-api.doka.com/users.read yard-api.doka.com/roles.read",
        responseType: "code",
        showDebugInformation: true,
        postLogoutRedirectUri: "https://yardcam.doka.com/login",
      };
    } else {
      this.authConfig = {
        issuer: environment.groupIdUrl,
        strictDiscoveryDocumentValidation: false,
        redirectUri: window.location.origin + "/login",
        clientId: "1ceba4e8-7771-4e5a-9b65-f7e8dbfbf09c",
        scope:
          "openid profile roles yardcam.doka.com/api api.doka.com/branches.read yard-api.doka.com/branches.read yard-api.doka.com/users.read yard-api.doka.com/roles.read",
        responseType: "code",
        postLogoutRedirectUri: window.location.origin + "login",
      };
    }
    this.oauthService.configure(this.authConfig);
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then((res) => {
        this.authenticationHeaders.Authorization = this.oauthService.getAccessToken();
        console.log("OAuth Response ", res);
        console.log(this.oauthService.getAccessToken());
        console.log(this.router.routerState.snapshot.url);
      })
      .catch((err) => {
        console.error("OAuth ERROR ", err);
        this.logout();
      });

    // Redirect to initially requested page
    this.oauthService.events.subscribe((event) => {
      if (event.type === "token_received") {
        let redirectUrl = sessionStorage.getItem('redirectUrl');
        try {
          if (this.oauthService.state) {
            // Assuming state contains a URL-encoded redirect path
            redirectUrl = decodeURIComponent(this.oauthService.state);
          }
        } catch (e) {
          console.error("Error decoding state parameter for redirect:", e);
        }
        sessionStorage.removeItem('redirectUrl'); // Clean up
        window.location.href = redirectUrl; // Redirect to the originally intended URL
        // Navigate to the decoded URL or home if none was provided/found
        /* this.router.navigateByUrl(redirectUrl.substring(redirectUrl.lastIndexOf("/"))).catch((err) => {
          console.error("Failed to navigate to redirectUrl:", redirectUrl, err);
          // Fallback navigation, e.g., to home
          this.router.navigateByUrl("/gallery").catch((err) => {
            console.error("Failed to navigate to fallback URL:", err);
          });
        }); */
      }
    });
  }

  /* Loggin out, means navigating to login Page and deleting token adn userid in App-Config */
  logout() {
    this.oauthService.logOut();
    this.navCrtl.navigateBack("/login");
  }

  get isOAuthLoggedIn() {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken() && this.oauthService.getAccessTokenExpiration() > (new Date()).getTime();
  }

  public oauthLogin(url?: string) {
    console.log("Login");
    this.oauthService.initLoginFlow(url);
  }

  public async getUser(): Promise<string> {
    let claims: any = this.oauthService.getIdentityClaims();
    return claims.preferred_username;
  }

  public async getUserId(): Promise<string> {
    const userId = (this.oauthService.getIdentityClaims() as any).sub;
    console.log("UserId", this.userId);
    return userId;
  }
}
