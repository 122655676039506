// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  groupIdUrl: "https://login.test.doka.com",
  yardCamApiUrl: "https://azwe-func-dev-yardc.azurewebsites.net",
  groupApiUrl: "https://api.test.doka.com",
  yardApiUrl: "https://yard-api.dev.doka.com",
  documentRoleId: "915521a7-7298-418d-fe27-08dc102fa3ce",
  translationsApiBaseUrl: "https://yard-api.dev.doka.com",
  identityServerClientId: "1ceba4e8-7771-4e5a-9b65-f7e8dbfbf09c"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
