import { LoginGuard } from "./guards/login.guard";
import { AuthenticationGuard } from "./guards/authentication.guard";
import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "gallery",
    loadChildren: () => import("./gallery/gallery.module").then((m) => m.GalleryPageModule),
    canActivate: [AuthenticationGuard],
  },
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () => import("./login/login.module").then((m) => m.LoginPageModule),
    canActivate: [LoginGuard],
  },
  {
    path: "documents",
    loadChildren: () => import("./documents/documents.module").then((m) => m.DocumentsPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
