// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#nav-bar {
  width: 400px;
  position: absolute;
  bottom: 0px;
  left: calc(50vw - 200px);
  background: #eeeeee;
  padding: 10px 10px 45px 10px;
  border: 1px solid black;
  border-bottom: 0px;
  border-radius: 5px 5px 0px 0px;
}
#nav-bar ion-segment-button::part(native) {
  border-color: #e0e0e0;
  background: #efefef;
  border-width: 3px;
}
#nav-bar ion-segment-button.segment-button-checked::part(native) {
  background: var(--ion-color-primary);
  border-color: var(--ion-color-primary);
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,wBAAA;EACA,mBAAA;EACA,4BAAA;EACA,uBAAA;EACA,kBAAA;EACA,8BAAA;AACF;AACE;EACE,qBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;AAEE;EACE,oCAAA;EACA,sCAAA;EACA,kBAAA;AAAJ","sourcesContent":["#nav-bar {\n  width: 400px;\n  position: absolute;\n  bottom: 0px;\n  left: calc(50vw - 200px);\n  background: #eeeeee;\n  padding: 10px 10px 45px 10px;\n  border: 1px solid black;\n  border-bottom: 0px;\n  border-radius: 5px 5px 0px 0px;\n\n  ion-segment-button::part(native) {\n    border-color: #e0e0e0;\n    background: #efefef;\n    border-width: 3px;\n  }\n\n  ion-segment-button.segment-button-checked::part(native) {\n    background: var(--ion-color-primary);\n    border-color: var(--ion-color-primary);\n    border-radius: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
