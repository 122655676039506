import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class RoleService {
  public isDocument = false;

  constructor(
    private apiService: ApiService
  ) {
    apiService.init();
  }

  public async isDocumentAllowed(): Promise<boolean> {
    if ((await this.apiService.getRolesByUser()).find(r => r.roleId == environment.documentRoleId))
    {
      return true;
    } else {
      return false;
    }
  }

  public async init() {
    this.isDocument = await this.isDocumentAllowed();
  }
}