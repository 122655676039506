import { NavController } from "@ionic/angular";
import { TranslationService } from "./services/translation.service";
import { Component } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./services/authentication.service";
import { RoleService } from "./services/role.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public environment = environment;
  public segment;

  constructor(private translationService: TranslationService, private navCtrl: NavController, public roleService: RoleService) {
    this.translationService.initTranslationService();
  }
  
  ngOnInit() {
    if (window.location.href.includes("documents")) {
      this.segment = "documents";
    } else {
      this.segment = "pictures";
    }  
  }

  isLogin() {
    return !window.location.href.includes("login");
  }

  onChangePage(page: "gallery" | "documents") {
    if (!window.location.href.includes(page)) {
      this.navCtrl.navigateForward(page);
    }
  }
}
