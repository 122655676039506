import { AuthenticationService } from "./authentication.service";
import { Branch } from "./../models/branch.model";
import { ImageModel } from "./../models/image.model";
import { OAuthService } from "angular-oauth2-oidc";
import { ImageSearchModel } from "./../models/imageSearch.model";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { Document } from "../models/document.model";
import { DocumentSearch } from "../models/documentSearch.model";
import { Voucher } from "../models/voucher.model";
import { lastValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class ApiService {
  private headers = {
    Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
    "accept-version": "1.0",
  };

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private authenticationService: AuthenticationService
  ) {}

  public init() {
    this.headers.Authorization = `Bearer ${this.oAuthService.getAccessToken()}`;
  }

  public async getImagesData(searchArguments: ImageSearchModel, top: number, skip: number): Promise<ImageModel[]> {
    console.table(searchArguments);
    return await lastValueFrom(
      this.http.get<ImageModel[]>(
        `${environment.yardCamApiUrl}/api/YardCamPicture?Top=${top}&Skip=${skip}${
          searchArguments.searchText ? "&SearchText=" + searchArguments.searchText.replace(/\s/g, "") : ""
        }${searchArguments.voucherType ? "&VoucherType=" + searchArguments.voucherType : ""}${
          searchArguments.isRelevant ? "&IsRelevant=" + searchArguments.isRelevant : ""
        }${typeof searchArguments.isDamaged == "boolean" ? "&DamagePicture=" + searchArguments.isDamaged : ""}${
          searchArguments.branch ? "&branchIdentifier=" + searchArguments.branch : ""
        }${searchArguments.isSortDateAscending ? "&isSortDateAscending=" + searchArguments.isSortDateAscending : ""}${
          searchArguments.beginDate ? "&beginDate=" + searchArguments.beginDate : ""
        }${searchArguments.endDate ? "&endDate=" + searchArguments.endDate : ""}`,
        { headers: this.headers }
      )
    );
  }

  public async getBranches(): Promise<Branch[]> {
    return await lastValueFrom(
      this.http.get<Branch[]>(`${environment.groupApiUrl}/branches?%24top=1000&%24skip=0`, { headers: this.headers })
    );
  }

  public async getImageBulk(imageIds: string[]) {
    return await lastValueFrom(
      this.http.post(`${environment.yardCamApiUrl}/api/YardCamPictureZip`, imageIds, {
        headers: this.headers,
        responseType: "blob",
      })
    );
  }

  public async getImage(imageId: string, size: "Full" | "Thumbnail", onChangeImageSize?: any): Promise<Blob> {
    return await lastValueFrom(
      this.http.get(`${environment.yardCamApiUrl}/api/YardCamPicture/${imageId}?PictureSize=${size}`, {
        headers: this.headers,
        responseType: "blob",
      })
    );
  }

  public async getImageCounter(): Promise<number> {
    return await lastValueFrom(
      this.http.get(`${environment.yardCamApiUrl}/api/YardCamPictureQuantity`, { headers: this.headers }).pipe(
        map((response: number) => {
          return response;
        })
      )
    );
  }

  public async saveRotation(image: ImageModel, rotation: number): Promise<void> {
    await lastValueFrom(
      this.http.post(
        `${environment.yardCamApiUrl}/api/YardCamPictureRotate/${image.pictureID}?rotation=${rotation}`,
        "",
        {
          headers: this.headers,
        }
      )
    );
  }

  public async patchImage(
    image: ImageModel,
    changeParameters: { voucherNumber?: string; isRelevant?: boolean }
  ): Promise<void> {
    await lastValueFrom(
      this.http.patch(
        `${environment.yardCamApiUrl}/api/YardCamPicture`,
        changeParameters.voucherNumber
          ? changeParameters.isRelevant
            ? {
                VoucherNumber: changeParameters.voucherNumber,
                IsRelevant: changeParameters.isRelevant,
                PictureId: image.pictureID,
              }
            : { VoucherNumber: changeParameters.voucherNumber, PictureId: image.pictureID }
          : changeParameters.isRelevant
          ? { IsRelevant: changeParameters.isRelevant, PictureId: image.pictureID }
          : {},
        { headers: this.headers }
      )
    );
  }

  public async deleteImage(image: ImageModel): Promise<void> {
    await lastValueFrom(
      this.http.delete(`${environment.yardCamApiUrl}/api/YardCamPicture/${image.pictureID}`, {
        headers: this.headers,
      })
    );
  }

  public async getDocuments(parameters: DocumentSearch, skip: number, top: number): Promise<Document[]> {
    return await lastValueFrom(
      this.http.get<Document[]>(
        `${environment.yardCamApiUrl}/api/documents?skip=${skip}&top=${top}${
          parameters.searchString ? "&searchString=" + parameters.searchString : ""
        }${parameters.voucherType ? "&voucherType=" + parameters.voucherType : ""}${
          parameters.owner ? "&owner=" + parameters.owner : ""
        }${parameters.branchIdentifier ? "&branchIdentifier=" + parameters.branchIdentifier : ""}&isUserFilter=${
          parameters.isUserFilter
        }&isProd=${environment.production}`,
        {
          headers: this.headers,
        }
      )
    );
  }

  public async getDocumentFile(documentId: string): Promise<Blob> {
    return await lastValueFrom(
      this.http.get(`${environment.yardCamApiUrl}/api/documents/${documentId}/file`, {
        headers: this.headers,
        responseType: "blob",
      })
    );
  }

  public async getDocumentFileAsPdf(documentId: string): Promise<Blob> {
    return await lastValueFrom(
      this.http.get(`${environment.yardCamApiUrl}/api/documents/${documentId}/pdf`, {
        headers: this.headers,
        responseType: "blob",
      })
    );
  }

  public async updateDocumentFile(document: Document, documentFile: Blob): Promise<Object> {
    let headers = {};
    Object.assign(headers, this.headers);
    headers = {
      ...headers,
      ...{
        "x-doc-clientid": document.branch,
        "x-doc-vouchernumber": document.voucherNumber,
        "x-doc-vouchertype": document.voucherType,
        "x-doc-name": document.name,
        "x-doc-modifier": await this.authenticationService.getUserId(),
      },
    };
    console.log(headers);
    return await lastValueFrom(
      this.http.put(`${environment.yardCamApiUrl}/api/documents`, documentFile, {
        headers,
      })
    );
  }

  public async deleteDocument(documentId: string): Promise<Object> {
    return await lastValueFrom(
      this.http.delete(`${environment.yardCamApiUrl}/api/documents/${documentId}`, {
        headers: this.headers,
      })
    );
  }

  public async getVoucher(voucherIdentifier: string, voucherType: string): Promise<Voucher> {
    return await lastValueFrom(
      this.http.get<Voucher>(
        `${environment.yardCamApiUrl}/api/voucher?voucherIdentifier=${voucherIdentifier}&voucherType=${voucherType}&isProd=${environment.production}`,
        { headers: this.headers }
      )
    );
  }

  public async uploadNewDocument(document: Document, documentFile: Blob) {
    const headers = {
      Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
      "accept-version": "1.0",
      "x-doc-vouchertype": document.voucherType,
      "x-doc-vouchernumber": document.voucherNumber,
      "x-doc-name": document.name,
      "x-doc-custname": document.customerName,
      "x-doc-custno": document.customerNumber,
      "x-doc-voucherdate": document.voucherDate.toString(),
      "x-doc-projectno": document.projectNumber,
      "x-doc-siteno": document.siteNumber,
      "x-doc-subjobsite": document.subSiteNumber,
      "x-doc-subsubjobsite": document.subSubSiteNumber,
      "x-doc-branchid": document.branch,
      "x-doc-modifier": await this.authenticationService.getUserId(),
      "x-doc-creationdate": document.creationDate.toISOString(),
      "x-doc-salesids": document.salesIDs,
      "x-doc-file-extension": document.type,
    };
    return await lastValueFrom(
      this.http.post(`${environment.yardCamApiUrl}/api/documents`, documentFile, {
        headers: headers,
        responseType: "text" as "json",
      })
    );
  }

  public async getBranchesDocuments(): Promise<object[]> {
    return await lastValueFrom(this.http
      .get<object[]>(
        `${
          environment.yardApiUrl
        }/api/users/${await this.authenticationService.getUserId()}/branches?%24top=1000&roleId=${
          environment.documentRoleId
        }`,
        { headers: this.headers }
      ));
  }

  public async getRolesByUser(): Promise<any[]> {
    return await lastValueFrom(this.http
      .get<any[]>(`${environment.yardApiUrl}/api/users/${await this.authenticationService.getUserId()}/roles`, {
        headers: this.headers,
      }));
  }

  public async getUserById(id: string): Promise<any> {
    return await lastValueFrom(this.http.get<any>(`${environment.yardApiUrl}/api/users/${id}`, { headers: this.headers }));
  }

  public async updateDocumentVoucher(documentId: string, voucherNumber: string): Promise<any> {
    const headers = {
      Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
      "accept-version": "1.0",
    };
    return await lastValueFrom(this.http
      .post<any>(
        `${environment.yardCamApiUrl}/api/documents/${documentId}/moveToVoucher?voucherNumber=${voucherNumber}`,
        {},
        { headers }
      ));
  }

  public async uploadNewImage(image: ImageModel, imageFile: Blob) {
    const headers = {
      Authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
      "accept-version": "1.0",
      "x-img-vouchertype": image.voucherType,
      "x-img-vouchernumber": image.voucherNumber,
      "x-img-custname": image.customerName,
      "x-img-custno": image.customerNumber,
      "x-img-voucherdate": image.voucherDate.toString(),
      "x-img-projectno": image.projectNumber,
      "x-img-siteno": image.siteNumber,
      "x-img-subjobsite": image.subJobSite,
      "x-img-subsubjobsite": image.subSubJobSite,
      "x-img-clientid": image.clientId,
      "x-img-creationdate": image.creationDate.toISOString(),
      "x-img-salesids": image.salesIDs,
      "x-img-file-extension": image.fileExtension,
      "x-img-damage": "false",
    };
    return await lastValueFrom(this.http
      .post<any>(`${environment.yardCamApiUrl}/api/YardCamPicture`, imageFile, { headers }));
  }
}
