import { AuthenticationService } from "./../services/authentication.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { NavController } from "@ionic/angular";

import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthenticationGuard  {
  path: import("@angular/router").ActivatedRouteSnapshot[];
  route: import("@angular/router").ActivatedRouteSnapshot;

  constructor(
    private navCtrl: NavController,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn = this.authenticationService.isOAuthLoggedIn;
    if (isLoggedIn) {
      console.log("LOGIN: success");
      return true;
    } else {
      console.log("is not logged in", window.location.origin + state.url);
      sessionStorage.setItem('redirectUrl', window.location.href);
      if (state.url.includes("searchText") || state.url.includes("voucherType") || state.url.includes("isRelevant")) {
        setTimeout(() => {
          this.authenticationService.oauthLogin(window.location.origin + state.url);
        }, 1000)
        return true;
      } else {
        this.navCtrl.navigateBack("/login");
        return false;
      }
    }
  }
}
