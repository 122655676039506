import { Injectable } from "@angular/core";
import { DokaI18NextService } from "@doka/i18next";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TranslationService {
  constructor(private dokaTranslation: DokaI18NextService) { }
  //Sets the initial language on app start (currently without PouchDB access)
  public async initTranslationService() {
    const language = navigator.language.slice(0, 2);
    await this.dokaTranslation.init(environment.translationsApiBaseUrl);
    await this.dokaTranslation.changeLanguage(language);
  }
}
