import { AuthenticationService } from "./../services/authentication.service";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";

@Injectable({ providedIn: "root" })
export class LoginGuard  {
  constructor(private authService: AuthenticationService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isOAuthLoggedIn) {
      return this.router.navigateByUrl("/gallery");
    } else {
      return true;
    }
  }
}
