import { NgxDocViewerModule } from "ngx-doc-viewer";
import { AuthInterceptor } from "./services/authinterceptor.service";
import { OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DokaCommonModule } from "@doka/common";
import { I18NextModule } from "angular-i18next";
import { I18N_PROVIDERS } from "./i18n";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        sendAccessToken: true,
        allowedUrls: ["https://yardcam.dev.doka.com/"],
      },
    }),
    NgxDocViewerModule,
    BrowserAnimationsModule,
    DokaCommonModule,
    I18NextModule.forRoot()
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: OAuthStorage, useValue: localStorage },
    I18N_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
